import {connect} from "react-redux"
import SignIn from "./sign-in"
import {signIn} from "./sign-in-actions"

const mapStateToProps = state => {
  return {
    user: state.signIn.user,
    error: state.signIn.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    submit: (email, password) => dispatch(signIn(email, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)