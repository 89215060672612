import React from 'react';
import ReactDOM from 'react-dom';
import App from './app-container';
import Amplify from 'aws-amplify'
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'

import root from "./reducer"

const store = createStore(root, {}, applyMiddleware(thunk))

// TODO: get this from environment and/or toggle dev/prod
Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_z2UHVJlZJ',
    userPoolWebClientId: '5nntt2d4pc0fcviah9q5l3hnr6',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    storage: localStorage,
  },
  API: {
    endpoints: [
      {
        name: 'tracer-v1',
        endpoint: 'https://vgaa3pgz5g.execute-api.us-east-1.amazonaws.com'
      }
    ]
  }
})

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
