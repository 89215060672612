import {constants} from "./main-actions"

const main = (state = {boxContents: {}}, action) => {

  switch (action.type) {
    case constants.START_FETCH_BOX_LIST:
      return {
        ...state,
        loading: true
      }

    case constants.FETCH_BOX_LIST_SUCCESS:
      return {
        ...state,
        boxes: action.boxes,
        loading: false
      }

    case constants.FETCH_BOX_CONTENTS_SUCCESS:
      return {
        ...state,
        boxContents: {
          ...state.boxContents,
          [action.box]: action.contents
        }
      }

    default:
      return {
        ...state
      }
  }

}

export default main