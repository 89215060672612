import React, { Component } from 'react';
import QRCode from "qrcode.react"
import {Table, Popover, OverlayTrigger} from "react-bootstrap";

// TODO: this may not need to be a class component
class PackagingView extends Component {

  render() {

    return (
      <Table striped>
        <thead>
          <tr>
            <th>Date Scanned</th>
            <th>Gift Box ID</th>
            <th>Serial Number</th>
          </tr>
        </thead>
        <tbody>
          {(this.props.data) ? this.props.data.map(box => {
            return (
              <tr>
                <td>{box.date}</td>
                <td>{box.giftBoxId}</td>
                <td>{(box.serial.id) ? box.serial.id : box.serial}</td>
              </tr>
            )
          }) : null}
        </tbody>
      </Table>
    )
  }

}

export default PackagingView