import {API, Auth} from "aws-amplify"

const START_FETCH_BOX_LIST = 'START_FETCH_BOX_LIST'
const FETCH_BOX_LIST_SUCCESS = 'FETCH_BOX_LIST_SUCCESS'
const FETCH_BOX_CONTENTS_SUCCESS = 'FETCH_BOX_CONTENTS_SUCCESS'
const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'

export const constants = {START_FETCH_BOX_LIST, FETCH_BOX_LIST_SUCCESS, FETCH_BOX_CONTENTS_SUCCESS, SIGN_OUT_SUCCESS}

export const fetchData = (user, facility) => {
  return dispatch => {
    dispatch({type: START_FETCH_BOX_LIST})

    API.get('tracer-v1', `/v1/${facility}/box/master`, {
      headers: {
        'Authorization': user.signInUserSession.idToken.jwtToken
      }
    })
      .then(response => dispatch({type: FETCH_BOX_LIST_SUCCESS, boxes: response}))
      .catch(err => console.log(err))
  }
}

export const fetchBoxContents = (box, user, facility) => {
  return dispatch => {

    API.get('tracer-v1', `/v1/${facility}/box/master/${box}`, {
      headers: {
        'Authorization': user.signInUserSession.idToken.jwtToken
      }
    })
      .then(response => dispatch({type: FETCH_BOX_CONTENTS_SUCCESS, box, contents: response}))
      .catch(err => console.log(err))
  }
}

export const signOut = () => {
  return dispatch => {
    Auth.signOut().then(() => dispatch({type: SIGN_OUT_SUCCESS}))
      .catch(err => console.log(err))
  }
}