import {connect} from "react-redux"
import {fetchData, fetchBoxContents, signOut} from "./main-actions"
import Main from "./main"

const mapStateToProps = state => {
  return {
    boxes: state.main.boxes,
    boxContents: state.main.boxContents,
    loading: state.main.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchMasterBoxes: (user, facility) => dispatch(fetchData(user, facility)),
    fetchBoxContents: (box, user, facility) => dispatch(fetchBoxContents(box, user, facility)),
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)