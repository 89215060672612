import {Auth} from "aws-amplify"

const SUBMIT_REGISTRATION = 'SUBMIT_REGISTRATION'
const GET_CONFIRMATION_CODE = 'GET_CONFIRMATION_CODE'
const SUBMIT_CONFIRMATION_CODE = 'SUBMIT_CONFIRMATION_CODE'
const REGISTRATION_ERROR = 'REGISTRATION_ERROR'
const CONFIRMATION_SUCCESS = 'CONFIRMATION_SUCCESS'

export const constants = {SUBMIT_REGISTRATION, GET_CONFIRMATION_CODE, SUBMIT_CONFIRMATION_CODE, REGISTRATION_ERROR, CONFIRMATION_SUCCESS}

export const submitRegistration = (name, email, password) => {
  return dispatch => {
    dispatch({type: SUBMIT_REGISTRATION})

    Auth.signUp({
      username: email,
      password,
      attributes: {
        name,
        email
      }
    })
      .then(data => dispatch({type: GET_CONFIRMATION_CODE}))
      .catch(err => dispatch(registrationError(err)))
  }
}

export const confirmRegistration = (email, code) => {
  return dispatch => {
    dispatch({type: SUBMIT_CONFIRMATION_CODE})

    Auth.confirmSignUp(email, code)
      .then(data => dispatch({type: CONFIRMATION_SUCCESS}))
      .catch(err => dispatch(registrationError(err)))
  }
}

export const registrationError = err => {
  return {
    type: REGISTRATION_ERROR,
    err
  }
}