import React, { Component } from 'react'
import logo from "../images/logo-colour.png"
import {Panel, FormGroup, FormControl, Alert, Button} from "react-bootstrap"
import {Redirect} from "react-router-dom"

class Register extends Component {

  constructor(props) {
    super(props)

    this.state = {
      nameValue: '',
      emailValue: '',
      passwordValue: '',
      codeValue: ''
    }
  }

  render() {
    if (this.props.status === 'SUCCESS') {
      return (<Redirect to={'/login?redirect=/'} />)
    }

    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <Panel>
          <Panel.Heading style={{display: 'flex', justifyContent: 'center'}}>
            <img alt='mysa logo' src={logo} style={{display: 'inline-block'}}/>
          </Panel.Heading>
          <Panel.Body>
            <form>
              <FormGroup>
                {(this.props.error) ? <Alert bsStyle={'danger'}>{this.props.error.message}</Alert> : null}

                <FormControl type={'text'} placeholder={'name'} onChange={e => this.setState({nameValue: e.target.value})} /><br/>
                <FormControl type={'text'} placeholder={'email@getmysa.com'} onChange={e => this.setState({emailValue: e.target.value})} /> <br/>
                {!(this.props.status === 'CONFIRM') ?
                  <FormControl value={this.state.passwordValue} type={'password'} placeholder={'password'}
                               onChange={e => this.setState({passwordValue: e.target.value})}/>
                  : <FormControl value={this.state.codeValue} type={'numeric'} placeholder={'verification code'}
                                 onChange={e => this.setState({codeValue: e.target.value})}/>
                }
              </FormGroup>

              {!(this.props.status === 'CONFIRM') ?
                <Button type={'submit'} bsStyle={'primary'}
                        onClick={e => this.props.submitRegistration(this.state.nameValue, this.state.emailValue, this.state.passwordValue)}>Submit</Button>
                : <Button type={'submit'} bsStyle={'primary'}
                         onClick={e => this.props.confirmRegistration(this.state.emailValue, this.state.codeValue)}>Confirm</Button>
              }
            </form>
          </Panel.Body>
        </Panel>
      </div>
    )
  }

}

export default Register