import {connect} from "react-redux"
import Register from "./register"
import {submitRegistration, confirmRegistration} from "./register-actions"

const mapStateToProps = state => {
  return {
    status: state.register.registrationStatus,
    error: state.register.registrationError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    submitRegistration: (name, email, password) => dispatch(submitRegistration(name, email, password)),
    confirmRegistration: (email, code) => dispatch(confirmRegistration(email, code))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)